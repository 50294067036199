

* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

.spinner-wrapper {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.spinner-border {
  width: 20%;
  height: 20%;
}


.mainFont {
  font-family: 'Roboto Condensed';
}

.mainFont-header {
  font-family: 'Roboto Condensed';
  font-size: 5vw;
  text-align: center;
  color: white;
}

.mainBg {
  background-color: black;
  width: 100vw;
}

@media only screen and (max-width: 600px) {

  .mainFont-header { 
     font-size: 9vw; 
  }
}





.bkrnd-visual-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.bkrnd-visual_gif {
  position: absolute;
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  
}

.landing-visual-header {
  font-family: DynaPuff; 
  font-size: 15vw; 
  font-weight: bold 700; 
  color: white;
}

.landing-visual-subheader {
  font-family: Roboto Condensed;
  font-size: 3vw;
  color: white;
  white-space: pre-wrap;
  word-break: break-word;
}


.Home {
  width: 100vw;

}

.speaker-page-text {
  text-align: center;
  font-family: Roboto Condensed; 
  font-size: 5vw; 
  color: white;
}


.meet {
  background-color: navy;
  width: 100vw;
  height: 100vh;
}

.speakingmain {
  background-color: black;
  width: 100vw;
}

.contactForm {
  background-color: black;
  width: 100vw;
}

.contact-bg {
  background-color: rgb(176, 184, 249);
  /* background-color:gainsboro; */
}

.speakMain_img_div {
  background-color: black;
}

.carousel-control-next-icon {
  filter: invert(100%);
}

.btnDark {
  color: black;
}

.btnDark:hover {
  color: white;
}

.btnLight {
  color: white;
}

.btnLight:hover {
  color: black;
}

.speakMain_img {
  border-radius: 22% 78% 27% 73% / 73% 21% 75% 20%; 
}


.landing-header {
color: white
}


.videos {
  /* background-color: gainsboro; */
  background-color: rgb(176, 184, 249);
  width: 100vw;

}

.values-header {
  text-align: center;
  font-family: Londrina Outline;
  color: white;
  font-size: 15vw;
}


.intermain {
  background-color: black;
  width: 100vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  }


.Speaker {
  background-color: rgb(221, 200, 130);
  text-align: center;
  width: 100vw;
  height: 100vh;
}

input,
textarea {
  margin: 10px 0px;
}


.news-button {
  color: black;
  border-color: black;
  font-weight: bolder;
}

.contactForm-name, .contactForm-email, .contactForm-org, .contactForm-mess {
  color: black;
}


.contactForm-send {
  align-items: end;
}


.collab-cont {
  background-color: white;
  width: 100vw;
}

.input[type="email"]::-webkit-input-placeholder {
  color: white;
}


.values-blue-sq {
  color: white;
  width: 15rem;
  height: 15rem;
  background-color: black;
  border: blue solid;
  border-width: 5px;
  border-radius: 20%;
}


.values-mag-sq {
  color: white;
  width: 15rem;
  height: 15rem;
  background-color: black;
  border: magenta solid;
  border-width: 5px;
  border-radius: 20%;
}


.values-purp-sq {
  color: white;
  width: 15rem;
  height: 15rem;
  background-color: black;
  border: rgb(52, 8, 134) solid;
  border-width: 5px;
  border-radius: 20%;
}


.values-org-sq {
  color: white;
  width: 15rem;
  height: 15rem;
  border: 5px solid rgba(225, 168, 50, 0.921);
  background-color: black;
  border-radius: 20%;
}

.values-blue-sq:hover {
  background-color: blue;
  transform: scale(1.1);
  transition: all, 1s;
}

.values-purp-sq:hover {
  background-color: rgb(52, 8, 134);
  transform: scale(1.1);
  transition: all, 1s;
}

.values-mag-sq:hover {
  background-color: magenta;
  transform: scale(1.1);
  transition: all, 1s;
}

.values-org-sq:hover {
  background-color: rgba(225, 168, 50);
  transform: scale(1.1);
  transition: all, 1s;
}

.aboutDesc {
  background: linear-gradient(to right, #f32170, 
  #ff6b08, #cf23cf, #eedd44); 
/* -webkit-text-fill-color: transparent;  */
-webkit-background-clip: text; 
}

.synest {
  background: linear-gradient(to right, #f32170, 
  #ff6b08, #cf23cf, #eedd44); 
-webkit-text-fill-color: transparent; 
-webkit-background-clip: text; 
}

.aboutWords {
  max-width: 100vw;
  display: inline-block;
  position: relative;
}

.aboutWordsShadow {
  position: absolute;
  opacity: 0.3;
}

.center-text-div {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}



.whitebreak {
  background-color: white;
  height: 3px;
}

.navbar {
  width: 100vw;
}

.navbar-brand {
  font-family: DynaPuff; 
  font-size: 0.9rem;
}

.navbar-link-style {
  font-family: Roboto Condensed; 
  font-weight: bold;
  font-size: 1.1rem;
  text-decoration: none; 
  color: black;
}

.nav-item {
  padding-inline: 5vw;
}

/* .navbar-expand-lg .navbar-nav .nav-item:hover {
    border-top: 4px solid orange;
    border-bottom: 4px solid orange;
} */

.nav-item:hover {
  border-top: 4px solid orange;
  border-bottom: 4px solid orange;
}

/* .navbar-nav .nav-item:active {
  border-top: 4px solid orange;
  border-bottom: 4px solid orange;
} */

.carouselExample {
  position: relative;
}           

.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: TranslateY(-50%);
}

.carousel-control-next {
  left: auto;
  right: 20px;
}

.videocard {
  /* background-color: rgb(145, 152, 203); */
  background-color: black;
}

wrapper {
  display: flex;
  justify-content: center;
}

.wrapper iframe {
  aspect-ratio: 16 / 9;
  height: 40vh;
  width: 100% !important;
}